import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-cleveland-ohio.png'
import image0 from "../../images/cities/scale-model-of-cleveland-cycle-tours-in-cleveland-ohio.png"
import image1 from "../../images/cities/scale-model-of-cleveland-bike-tours-in-cleveland-ohio.png"
import image2 from "../../images/cities/scale-model-of-superelectric-pinball-parlor-in-cleveland-ohio.png"
import image3 from "../../images/cities/scale-model-of-lena-love-photography-in-cleveland-ohio.png"
import image4 from "../../images/cities/scale-model-of-uss-cod-submarine-memorial-in-cleveland-ohio.png"
import image5 from "../../images/cities/scale-model-of-cleveland-metroparks-zoo-in-cleveland-ohio.png"
import image6 from "../../images/cities/scale-model-of-buckland-museum-of-witchcraft-&-magick-in-cleveland-ohio.png"
import image7 from "../../images/cities/scale-model-of-boomerang-line-in-cleveland-ohio.png"
import image8 from "../../images/cities/scale-model-of-a-christmas-story-house-museum-in-cleveland-ohio.png"
import image9 from "../../images/cities/scale-model-of-international-women's-air-&-space-museum-in-cleveland-ohio.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Cleveland'
            state='Ohio'
            image={image}
            lat='41.49932'
            lon='-81.69436050000002'
            attractions={ [{"name": "Cleveland Cycle Tours", "vicinity": "2061 Gehring Ave, Cleveland", "types": ["point_of_interest", "establishment"], "lat": 41.4831632, "lng": -81.70032129999998}, {"name": "Cleveland Bike Tours", "vicinity": "1050 E 9th St Suite B, Cleveland", "types": ["point_of_interest", "establishment"], "lat": 41.5095788, "lng": -81.69517880000001}, {"name": "Superelectric Pinball Parlor", "vicinity": "6500 Detroit Ave, Cleveland", "types": ["point_of_interest", "establishment"], "lat": 41.4842367, "lng": -81.73043459999997}, {"name": "Lena Love Photography", "vicinity": "1662 Glenmont Rd, Cleveland Heights", "types": ["point_of_interest", "establishment"], "lat": 41.5141284, "lng": -81.57962859999998}, {"name": "USS Cod Submarine Memorial", "vicinity": "1201 N Marginal Rd, Cleveland", "types": ["point_of_interest", "establishment"], "lat": 41.510065, "lng": -81.69155699999999}, {"name": "Cleveland Metroparks Zoo", "vicinity": "3900 Wildlife Way, Cleveland", "types": ["zoo", "point_of_interest", "establishment"], "lat": 41.4459344, "lng": -81.71261340000001}, {"name": "Buckland Museum of Witchcraft & Magick", "vicinity": "2155 Broadview Rd, Cleveland", "types": ["museum", "home_goods_store", "book_store", "store", "point_of_interest", "establishment"], "lat": 41.4380495, "lng": -81.6998236}, {"name": "Boomerang Line", "vicinity": "Wildlife Way, Cleveland", "types": ["point_of_interest", "establishment"], "lat": 41.44509180000001, "lng": -81.71357269999999}, {"name": "A Christmas Story House Museum", "vicinity": "1103 Rowley Ave, Cleveland", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.4686985, "lng": -81.6878906}, {"name": "International Women's Air & Space Museum", "vicinity": "1501 N Marginal Rd, Cleveland", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5114652, "lng": -81.68994459999999}] }
            attractionImages={ {"Cleveland Cycle Tours":image0,"Cleveland Bike Tours":image1,"Superelectric Pinball Parlor":image2,"Lena Love Photography":image3,"USS Cod Submarine Memorial":image4,"Cleveland Metroparks Zoo":image5,"Buckland Museum of Witchcraft & Magick":image6,"Boomerang Line":image7,"A Christmas Story House Museum":image8,"International Women's Air & Space Museum":image9,} }
       />);
  }
}